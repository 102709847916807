import React from "react";

import TopBar from "../page_components/TopBar";
import NavBar from "../page_components/NavBar";
import MainCarousel from "../page_components/MainCarousel";
import Services from "../page_components/Services";
import Footer from "../page_components/Footer";

import {Helmet} from "react-helmet";

function App() {
    return (
        <React.Fragment>
            <Helmet>
                <title>Delta Abogados</title>
            </Helmet>
            <TopBar/>
            <NavBar/>
            <MainCarousel/>
            <Services/>
            <Footer/>
        </React.Fragment>
    );
}

export default App;
