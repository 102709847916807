import React from "react";

import Pages from "../../page_components/Pages";
import Services from "../../page_components/Services";

function App() {
    return (
        <Pages title="Servicios">
            <Services/>
        </Pages>
    );
}

export default App;
