import React from "react";

import Pages from "../page_components/Pages";

function ServicePage() {

    return (
        <Pages title="Acerca de Nosotros">
            <div className="service-page mt-md-5 pt-md-5 pb-4">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="service-img">
                                <img src={"/logo_vertical_gris.png"} alt="Logo Delta Abogados"/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="section-header">
                                <h2>Acerca de Nosotros</h2>
                            </div>
                            <div className="service-text mx-5 mx-md-0">
                                <p>
                                    Nuestro estudio jurídico funciona en la ciudad de Rancagua,
                                    integrado por abogados comprometidos con brindar asesorías
                                    de excelencia profesional y ética a favor de las personas y
                                    empresas de la VI Región, en la prevención de sus conflictos
                                    legales, o cuando se han visto vulnerados en sus derechos o
                                    impedidas de ejercerlos libremente.
                                </p>
                                <p>
                                    Estamos al servicio a las personas y la comunidad empresarial,
                                    promotora de los derechos ciudadanos y su respeto, facilitándoles
                                    el acceso a una asesoría legal de excelencia profesional y ética;
                                    comprometida, dedicada y eficaz; capaz de contribuir al mejoramiento
                                    de la calidad de vida de los chilenos. Desde su fundación. Delta
                                    Abogados, se ha comprometido con la excelencia profesional. Es una
                                    convicción básica de la firma, que el ejercicio del derecho es una
                                    actividad honorable y enriquecedora que trae consigo la responsabilidad
                                    de seguir los más altos estándares éticos.
                                </p>

                            </div>
                        </div>
                    </div>
                    <div className="row service-text mx-5 mx-md-0" style={{textAlign: "center"}}>
                        <p>
                            Desde su fundación. Delta Abogados, se ha comprometido con la excelencia profesional.
                            Es una convicción básica de la firma, que el ejercicio del derecho es una actividad
                            honorable y enriquecedora que trae consigo la responsabilidad de seguir los más altos
                            estándares éticos. Nuestro compromiso es ayudar a nuestros clientes a alcanzar sus metas
                            ofreciéndoles asesorías jurídicas con un enfoque comercial de la más alta calidad que
                            supere sus expectativas. Nuestros clientes son nuestra primera prioridad.
                        </p>

                        <p>
                            Los clientes deberían elegirnos por que somos una firma de servicios legales integral y
                            ampliamente reconocida. Eso nos permite ofrecer un excelente servicio a nuestros clientes
                            y crear un entorno de trabajo donde las personas talentosas logran alcanzar el éxito.
                            contamos con un equipo de trabajo asequible y abierto, que combina la efectividad con la
                            cohesión y la colegialidad de nuestros abogados.

                        </p>
                    </div>
                </div>
            </div>
        </Pages>
    );
}

export default ServicePage;