import React from "react";

import WhatsAppButton from "./WhatsAppButton";
import Pages from "./Pages";

function ServicePage(props) {

    return (
        <Pages title={props.title}>
            <div className="service-page mt-md-5 pt-md-5 pb-4">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="service-img">
                                <img src={"/img/" + props.imagePath} alt={props.title}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="section-header">
                                <h2>{props.title}</h2>
                            </div>
                            <div className="service-text">
                                <p>
                                    {props.body}
                                </p>
                                <WhatsAppButton
                                    number="56952169218"
                                    text={"¡Hola! Visite su página y quiero saber más información acerca de " + props.title}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Pages>
    );
}

export default ServicePage;