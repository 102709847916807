import React, {useEffect} from "react";

import {Link, useLocation} from 'react-router-dom';

import {Nav, Navbar} from 'react-bootstrap';

function NavBar() {

    const {pathname} = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <Navbar className="navbar bg-dark navbar-dark" bg="dark" expand="lg">
            <Navbar.Brand className="navbar-brand" as={Link} to="/">
                <img
                    src={'/logo_horizontal_blanco.png'}
                    alt="Logo de Delta Abogados"
                />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
            <Navbar.Collapse id="basic-navbar-nav justify-content-between">
                <Nav className="ml-auto">
                    <Nav.Link className="nav-item nav-link" as={Link} to="/">Inicio</Nav.Link>
                    <Nav.Link className="nav-item nav-link" as={Link} to="/acerca">Acerca</Nav.Link>
                    <Nav.Link className="nav-item nav-link" as={Link} to="/servicios">Servicios</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}

export default NavBar;