import React from "react";

import ServicePage from "../../page_components/ServicePage";

function DefensaDeudores() {
    return (
        <ServicePage
            imagePath="icon-3-512.png"
            title="Defensa Deudores"
            body="Nos hemos especializados en la defensa acérrima de los derechos de aquellas personas que actualmente están en cesación de pagos, buscando lo mejor para sus derechos e intereses."
        />
    );
}

export default DefensaDeudores;
