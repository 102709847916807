import React from "react";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";

import generateWhatsAppURL from "../utils/generateWhatsAppURL";

function WhatsAppButton(props) {
    return (
        <a className="btn" target="_blank" rel="noreferrer"
           href={generateWhatsAppURL(props.number, props.text)}>
            <FontAwesomeIcon icon={faWhatsapp} size="lg"/> ¡Contactar ahora!
        </a>
    );
}

export default WhatsAppButton