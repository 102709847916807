import React from "react";

import ServicePage from "../../page_components/ServicePage";

function LiquidacionPersonasNaturalesEmpresas() {
    return (
        <ServicePage
            imagePath="icon-4-512.png"
            title="Liquidación Personas Naturales Y Empresas"
            body="Terminar con la angustia de los créditos impagos y las persecusiones de las casas comerciales es necesario. El procedimiento de liquidación concursal, antes llamado quiebra, permite re-iniciar los proyectos pospuestos sin la presión y el acoso constante de los acreedores."
        />
    );
}

export default LiquidacionPersonasNaturalesEmpresas;
