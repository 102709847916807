import React from "react";

import {Link} from 'react-router-dom';

function Services() {
    return (
        <div className="service">
            <div className="container">
                <div className="section-header">
                    <h2>Nuestros Servicios</h2>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="service-item">
                            <img
                                src={'/img/icon-1-64.png'}
                                alt="Icon"
                            />
                            <h3>JUICIOS LABORALES</h3>
                            <p>
                                Contamos con amplia experiencia en materia laboral. Juicios de despido injustificado, indebido o improcedente, nulidad de despido, tutela...
                            </p>
                            <Link to="/servicios/juicios-laborales">Leer más</Link>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="service-item">
                            <img
                                src={'/img/icon-2-64.png'}
                                alt="Icon"
                            />
                            <h3>JUICIOS CIVILES</h3>
                            <p>
                                Tenemos un equipo de abogados experimentados en juicios que se ventilan en Tribunales ordinarios. Ello incluye juicios de...
                            </p>
                            <Link to="/servicios/juicios-civiles">Leer más</Link>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="service-item">
                            <img
                                src={'/img/icon-3-64.png'}
                                alt="Icon"
                            />
                            <h3>DEFENSA DE DEUDORES</h3>
                            <p>
                                Nos hemos especializados en la defensa acérrima de los derechos de aquellas personas que actualmente están en cesación de pagos, buscando...
                            </p>
                            <Link to="/servicios/defensa-deudores">Leer más</Link>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="service-item">
                            <img
                                src={'/img/icon-4-64.png'}
                                alt="Icon"
                            />
                            <h3>LIQUIDACIÓN PERSONAS NATURALES Y EMPRESAS</h3>
                            <p>
                                Terminar con la angustia de los créditos impagos y las persecusiones de las casas comerciales es necesario. El procedimiento de liquidación...
                            </p>
                            <Link to="/servicios/liquidacion-personas-naturales-empresas">Leer más</Link>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="service-item">
                            <img
                                src={'/img/icon-5-64.png'}
                                alt="Icon"
                            />
                            <h3>JUICIOS PENALES</h3>
                            <p>
                                Resulta necesario contar con asesoría experta para que esta clase de situaciones no se transforme en un problema insoluble. Contamos con esa ayuda y podemos asistirlo de...
                            </p>
                            <Link to="/servicios/juicios-penales">Leer más</Link>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="service-item">
                            <img
                                src={'/img/icon-6-64.png'}
                                alt="Icon"
                            />
                            <h3>JUICIOS DE FAMILIA</h3>
                            <p>
                                Los problemas de pensión de alimentos, relación directa y regular (visitas), cuidado personal y divorcio, requieren la ayuda de expertos. Podemos ofrecer esa ayuda...
                            </p>
                            <Link to="/servicios/juicios-familia">Leer más</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Services;