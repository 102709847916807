import React from "react";

import Pages from "../page_components/Pages";

function NotFoundPage() {

    return (
        <Pages title="Error 404">
            <div className="service-page mt-md-5 pt-md-5 pb-4">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <div className="section-header">
                                <h2>Error 404</h2>
                            </div>
                            <div className="service-text mx-5 mx-md-0">
                                <p>
                                    ¡Página no encontrada!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Pages>
    );
}

export default NotFoundPage;