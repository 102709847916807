import React from "react";

import ServicePage from "../../page_components/ServicePage";

function JuiciosLaborales() {
    return (
        <ServicePage
            imagePath="icon-1-512.png"
            title="Juicios Laborales"
            body="Contamos con amplia experiencia en materia laboral. Juicios de despido injustificado, indebido o improcedente, nulidad de despido, tutela de derechos laborales, cobro de prestaciones, entre otros."
        />
    );
}

export default JuiciosLaborales;
