import React from "react";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFacebook, faInstagram, faWhatsapp} from '@fortawesome/free-brands-svg-icons'

import WhatsAppURL from "../utils/generateWhatsAppURL";

function TopBar() {

    return (
        <div className="top-bar d-none d-md-block">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-8">
                        <div className="top-bar-left">
                            <div className="text">
                                <i className="far fa-clock"/>
                                <h2>9:30 - 17:00</h2>
                                <p>Lunes - Viernes</p>
                            </div>
                            <div className="text">
                                <i className="fa fa-phone-alt"/>
                                <h2>+56 (72 2) 223322</h2>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="top-bar-right">
                            <div className="social">
                                <a target="_blank" rel="noreferrer"
                                   href="https://www.facebook.com/Delta-Abogados-Rancagua-261689134032848/">
                                    <FontAwesomeIcon icon={faFacebook}/>
                                </a>
                                <a target="_blank" rel="noreferrer"
                                   href="https://www.instagram.com/deltaabogados/">
                                    <FontAwesomeIcon icon={faInstagram}/>
                                </a>
                                <a className="btn" target="_blank" rel="noreferrer"
                                   href={WhatsAppURL("56952169218", "¡Hola! Visite su página y quiero saber más información")}>
                                    <FontAwesomeIcon icon={faWhatsapp}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TopBar;