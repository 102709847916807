import React from "react";

import {Carousel} from "react-bootstrap";

import WhatsAppButton from "./WhatsAppButton";

function MainCarousel() {

    return (
        <Carousel>
            <Carousel.Item>
                <div className="carousel-img">
                    <img
                        className="d-block w-100 "
                        src={'/img/carousel-1.jpg'}
                        alt="Juicios Laborales"
                    />
                </div>
                <Carousel.Caption className="carousel-text">
                    <h5>Juicios Laborales</h5>
                    <p>Juicios de despido injustificado, indebido o improcedente, nulidad de despido, tutela de derechos
                        laborales, cobro de prestaciones, entre otros.</p>
                    <div className="carousel-btn">
                        <WhatsAppButton
                            number="56952169218"
                            text="¡Hola! Visite su página y quiero saber más información acerca de los juicios laborales"
                        />
                    </div>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <div className="carousel-img">
                    <img
                        className="d-block w-100 "
                        src={'/img/carousel-2.jpg'}
                        alt="Defensa Deudores"
                    />
                </div>
                <Carousel.Caption className="carousel-text">
                    <h5>Defensa Deudores</h5>
                    <p>Nos hemos especializados en la defensa acérrima de los derechos de aquellas personas que
                        actualmente están en cesación de pagos, buscando lo mejor para sus derechos e intereses.</p>
                    <div className="carousel-btn">
                        <WhatsAppButton
                            number="56952169218"
                            text="¡Hola! Visite su página y quiero saber más información acerca de defensa de deudores"
                        />
                    </div>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <div className="carousel-img">
                    <img
                        className="d-block w-100 "
                        src={'/img/carousel-3.jpg'}
                        alt="Juicios de Familia"
                    />
                </div>
                <Carousel.Caption className="carousel-text">
                    <h5>Juicios de Familia</h5>
                    <p>Los problemas de pensión de alimentos, relación directa y regular (visitas), cuidado personal y
                        divorcio, requieren la ayuda de expertos. Podemos ofrecer esa ayuda.</p>
                    <div className="carousel-btn">
                        <WhatsAppButton
                            number="56952169218"
                            text="¡Hola! Visite su página y quiero saber más información acerca de los juicios de familia"
                        />
                    </div>
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
    );
}

export default MainCarousel;