import React, {useEffect} from "react";

import {Route, Switch, withRouter} from "react-router-dom";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";

import Acerca from "./pages/Acerca";
import Home from "./pages/Home"
import NotFoundPage from "./pages/404";

import DefensaDeudores from "./pages/servicios/DefensaDeudores";
import JuiciosCiviles from "./pages/servicios/JuiciosCiviles";
import JuiciosFamilia from "./pages/servicios/JuiciosFamilia";
import JuiciosLaborales from "./pages/servicios/JuiciosLaborales";
import JuiciosPenales from "./pages/servicios/JuiciosPenales";
import LiquidacionPersonasNaturalesEmpresas from "./pages/servicios/LiquidacionPersonasNaturalesEmpresas";
import Servicios from "./pages/servicios/Servicios"

import generateWhatsAppURL from "./utils/generateWhatsAppURL";

import ReactGA from 'react-ga';

ReactGA.initialize('UA-204032442-1');

function App() {

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    });

    return (
        <>
            <Switch>
                <Route exact path="/">
                    <Home/>
                </Route>
                <Route exact path="/acerca">
                    <Acerca/>
                </Route>
                <Route exact path="/servicios">
                    <Servicios/>
                </Route>
                <Route exact path="/servicios/juicios-laborales">
                    <JuiciosLaborales/>
                </Route>
                <Route exact path="/servicios/juicios-civiles">
                    <JuiciosCiviles/>
                </Route>
                <Route exact path="/servicios/defensa-deudores">
                    <DefensaDeudores/>
                </Route>
                <Route exact path="/servicios/liquidacion-personas-naturales-empresas">
                    <LiquidacionPersonasNaturalesEmpresas/>
                </Route>
                <Route exact path="/servicios/juicios-penales">
                    <JuiciosPenales/>
                </Route>
                <Route exact path="/servicios/juicios-familia">
                    <JuiciosFamilia/>
                </Route>
                <Route>
                    <NotFoundPage/>
                </Route>
            </Switch>
            <a
                href={generateWhatsAppURL("56952169218", "¡Hola! Visite su página y quiero más información")}
                class="whatsapp_float"
                target="_blank"
                rel="noopener noreferrer"
            >
                <FontAwesomeIcon 
                    icon={faWhatsapp} 
                    style={{
                        color: "#ffffff"
                    }}
                />
            </a>
        </>
    );
}

export default withRouter(App);
