import React from "react";

import {Link} from "react-router-dom";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import {faAngleRight, faEnvelope, faMapMarkerAlt, faPhoneAlt} from '@fortawesome/free-solid-svg-icons'

function Footer() {
    return (
        <div className="footer">
            <div className="footer-centered">
                <div className="container">
                    <div className="row">
                        <div className="col-md-5">
                            <div className="ml-2 ml-md-5 mb-5 mt-md-3">
                                <img
                                    src={'/logo_vertical_blanco.png'}
                                    alt="Logo de Delta Abogados"
                                    height="190px"
                                />
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="footer-contact">
                                        <h2>Nuestra Oficina</h2>
                                        <p><FontAwesomeIcon className="mr-1" icon={faMapMarkerAlt}/> Astorga 389,
                                            Rancagua, O'Higgins.</p>
                                        <p><FontAwesomeIcon className="mr-1" icon={faPhoneAlt}/> +56 (72 2) 223322</p>
                                        <p><FontAwesomeIcon className="mr-1" icon={faPhoneAlt}/> +56 (72 2) 229170</p>
                                        <p><FontAwesomeIcon className="mr-1"
                                                            icon={faEnvelope}/> contacto@abogadosdelta.cl
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="footer-link">
                                        <h2>Enlaces</h2>
                                        <Link to="/">
                                            <FontAwesomeIcon className="mr-1" icon={faAngleRight}/> Inicio
                                        </Link>
                                        <Link to="/acerca">
                                            <FontAwesomeIcon className="mr-1" icon={faAngleRight}/> Acerca
                                        </Link>
                                        <Link to="/servicios">
                                            <FontAwesomeIcon className="mr-1" icon={faAngleRight}/> Servicios
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container copyright">
                    <div className="row d-flex justify-content-center">
                        <div className="col-6">
                            <p>&copy; {(new Date().getFullYear())} <a href="https://www.abogadosdelta.cl/">Delta Abogados</a>. Todos los derechos reservados.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;