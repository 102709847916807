import React from "react";

import TopBar from "./TopBar";
import NavBar from "./NavBar";
import Footer from "./Footer";

import {Helmet} from "react-helmet";

function Pages(props) {
    return (
        <React.Fragment>
            <Helmet>
                <title>{props.title} | Delta Abogados</title>
            </Helmet>
            <TopBar/>

            <div className={"page"}>
                <NavBar/>
                <div className="my-5 py-5">
                    {props.children}
                </div>
                <Footer/>
            </div>
        </React.Fragment>
    );
}

export default Pages;
