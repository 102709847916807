import React from "react";

import ServicePage from "../../page_components/ServicePage";

function JuiciosFamilia() {
    return (
        <ServicePage
            imagePath="icon-6-512.png"
            title="Juicios Familia"
            body="Los problemas de pensión de alimentos, relación directa y regular (visitas), cuidado personal y divorcio, requieren la ayuda de expertos. Podemos ofrecer esa ayuda."
        />
    );
}

export default JuiciosFamilia;
