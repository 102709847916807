import React from "react";

import ServicePage from "../../page_components/ServicePage";

function JuiciosPenales() {
    return (
        <ServicePage
            imagePath="icon-5-512.png"
            title="Juicios Penales"
            body="Resulta necesario contar con asesoría experta para que esta clase de situaciones no se transforme en un problema insoluble. Contamos con esa ayuda y podemos asistirlo de manera correcta. "
        />
    );
}

export default JuiciosPenales;
