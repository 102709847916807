import React from "react";

import ServicePage from "../../page_components/ServicePage";

function JuiciosCiviles() {
    return (
        <ServicePage
            imagePath="icon-2-512.png"
            title="Juicios Civiles"
            body="Tenemos un equipo de abogados experimentados en juicios que se ventilan en Tribunales ordinarios. Ello incluye juicios de arriendo, indemnización de perjuicios, sumarios, precarios, reivindicaciones."
        />
    );
}

export default JuiciosCiviles;
